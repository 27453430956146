import React from 'react';
import PropTypes from 'prop-types';
import Buttons from '../../components/Buttons';
import Slider from '../../components/Slider';
import { withWidth } from '../../components/withWidth';
import constants from '../../constants/javascriptConstants';


class Index extends React.Component {
    static propTypes = {
        width: PropTypes.number.isRequired,
    };

    render() {
        const { width } = this.props;

        return (
            <section id="buttons" ref={this.sliderRef}>
                <Slider animateOver indexPage>
                    <Buttons width={width} competencesType={constants.competencesTypes.future}/>
                </Slider>
            </section>
        );
    }
}

export default  withWidth(Index);
